f
<template>
  <div class="position-relative slide clickable-item">
    <div :style="`background-image: url(${eventData.image_url})`" class="slide slide-bg position-absolute"></div>
    <ClientOnly>
      <img loading="lazy" v-image :src="eventData.image_url" class="slide slide-img position-relative" />
    </ClientOnly>
  </div>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <p class="mb-2">
      <router-link :to="{ name: 'events' }">
        <span class="small clickable-item-hov"> See all Events </span>
      </router-link>
      <span class="small clickable-item-hov"> > </span>
      <router-link to="#">
        <span class="small clickable-item-hov">{{ eventData.title }}</span>
      </router-link>
    </p>
    <div class="w-100">
      <h1 class="title">{{ eventData.title }}</h1>
      <div v-show="eventData.description" class="text-black description" v-html="eventData.description" />
    </div>
    <p v-if="dateHasPassed(eventData.ends_at)">Event has ended</p>

    <strong class="text-primary mt-3 mb-1">My points</strong>
    <div class="points text-primary my-0">
      <span class="text-black"> You have earned </span>
      {{ eventData.user_points }}
      <span class="text-black"> {{ pointNamePlural }} from this event. </span>
    </div>

    <div class="py-3">
      <event-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
      <submission-horizontal-card
        v-if="currentTab === 'ongoing'"
        :submission-tasks="ongoingTasks"
        :current-tab="currentTab"
        @updated="recalcTaskTimes"
      />
      <submission-horizontal-card
        v-if="currentTab === 'upcoming'"
        :submission-tasks="upcomingTasks"
        :current-tab="currentTab"
        @updated="recalcTaskTimes"
      />
      <submission-horizontal-card
        v-if="currentTab === 'submission'"
        :submission-tasks="tasksSubmittedTo"
        :current-tab="currentTab"
        @updated="recalcTaskTimes"
        hideHasSubmitted
      />
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import EventTabs from './components/event-tabs.vue';
import SubmissionHorizontalCard from './components/SubmissionHorizontalCardPreview.vue';
import { formatDescDateTime, formatTimeAgo, dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { SiteEvent, SiteEventTask, SiteEventTaskSubmission, Tab } from '@/shared/types/static-types';
import {
  getEventDetails,
  getEventTasks,
  getEventTasksWithSubmissions
} from '@/shared/actions/events';

import { eventDetailsTabs } from '@/shared/statics/tabs';
@Options({
  name: 'EventDetailPage',
  components: { EventTabs, SubmissionHorizontalCard },
})
export default class EventDetailPage extends Vue {
  public eventData: SiteEvent = {
    id: '',
    title: '',
    slug: '',
  };

  public tasks: SiteEventTask[] = [];
  public submissions: SiteEventTaskSubmission[] = [];
  public ongoingTasks: SiteEventTask[] = [];
  public upcomingTasks: SiteEventTask[] = [];
  public tabs = eventDetailsTabs;
  public currentTab: string = 'ongoing';
  public tabIndex = 0;
  public subTabIndex = 0;
  public tasksSubmittedTo: any = [];
  public upcomingSubmissionTasks = [];
  dateHasPassed = dateHasPassed;
  formatDescDateTime = formatDescDateTime;
  formatFromNowInMilliseconds = formatFromNowInMilliseconds;

  public get currentEventSlug() {
    const router = useRouter();
    return router.currentRoute.value.params.slug;
  }

  public hasUserSubmitted(task: SiteEventTask) {
    return task.user_submission_count && task.user_submission_count > 0;
  }

  public getTaskPath(task: SiteEventTask) {
    if (task.task_type === 'poll') return `/events/poll/${task.id}`;
    return `/events/task/${task.id}`;
  }

  public get pointNamePlural() {
    let pointName = 'point';
    if (this.eventData.point_name) pointName = this.eventData.point_name;
    if (!this.eventData.user_points || this.eventData.user_points !== 1) pointName += 's';
    return pointName;
  }

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    const route = useRoute();
    this.currentTab = value;
    router.replace({ name: 'event-details-preview', params: { slug: route.params.slug }, query: { tab: value } });
  }

  public async fetchEventDetails() {
    this.eventData = await getEventDetails(this.currentEventSlug as string);
    const [data, tasksSubmittedTo] = await Promise.all([
      getEventTasks(this.eventData.id as string),
      getEventTasksWithSubmissions(this.eventData.id as string)]);
    this.tasksSubmittedTo = tasksSubmittedTo.results;
    this.tasks = data.results;
    this.ongoingTasks = this.tasks.filter(
      (task: SiteEventTask) =>
        (!task.ends_at || !dateHasPassed(task.ends_at)) && (!task.starts_at || dateHasPassed(task.starts_at))
    );
    this.upcomingTasks = this.tasks.filter((task: SiteEventTask) => task.starts_at && !dateHasPassed(task.starts_at));
  }

  public recalcTaskTimes() {
    this.ongoingTasks = this.tasks.filter(
      (task: SiteEventTask) =>
        (!task.ends_at || !dateHasPassed(task.ends_at)) && (!task.starts_at || dateHasPassed(task.starts_at))
    );
    this.upcomingTasks = this.tasks.filter((task: SiteEventTask) => task.starts_at && !dateHasPassed(task.starts_at));
  }

  public async mounted() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchEventDetails();
  }
}
</script>
<style lang="sass" scoped>
.clickable-item-hov
  color: var(--ion-color-primary) !important

.points
  border: 2px solid
  border-radius: 12px
  padding: 10px

.slide
  width: 100%
  max-height: 300px
  min-height: 100px
  object-fit: cover

.slide-bg
  background-size: cover
  opacity: 0.3
  height: 100%

.slide-img
  object-fit: contain
  z-index: 99

.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
.submission-form
  margin-bottom: 1rem
.no-select
  user-select: none !important
.description
  flex: 1
  white-space: pre-wrap
  height: auto
  font-size: 14px
.expired
  color: gray !important
.event-name
  font-size: 1.15rem
a:hover
  opacity: 0.7
.completed
  position: relative
  top: 2px
  font-size: 10px
  margin-left: 5px
.evt-img
  img
    width: 200px
    object-fit: cover
    flex-shrink: 0
    width: 200px
.event-header
  display: flex
  flex-flow: row wrap
@media only screen and (max-width: 570px)
  .event-header
    flex-flow: column wrap
  .evt-img
    align-self: center
    margin-bottom: 0.2rem
</style>
