<template>
  <div>
    <EventCard :task="task" v-for="task in submissionTasks" :hideHasSubmitted="hideHasSubmitted"/>

    <div v-if="!submissionTasks.length" class="expired text-center mt-3">End of list</div>
  </div>
</template>

<script lang="ts" setup>
import EventCard from './EventCardPreview.vue';

const props = defineProps({
  submissionTasks: {
    type: Array,
    default: () => [],
  },
  hideHasSubmitted: {
    type: Boolean,
    default: false,
  }
});
const submissionTasks: any = toRef(props, 'submissionTasks');
const hideHasSubmitted: any = toRef(props, 'hideHasSubmitted');
</script>

<style lang="sass" scoped>
a:hover
  opacity: 0.7
.expired
  color: gray !important
</style>
